.notFound{
    position: fixed;
    left:0;
    top:0;
    background: rgba(0,0,0,0.8);
    z-index: 10;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    &-wrap{
        height:450px;
        width: 400px;
        background: var(--white-color);
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &-text{
        font-size: 25px;
        font-weight: bold;
        text-align: center;
    }
    &-figure{
        text-align: center;
    }
    &-img{
        width: 50px;
    }
    &-button{
        width: 100%;
        margin-top: 30px;
    }
}