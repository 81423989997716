.header{
    &-wrap{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        //margin: 0px auto;
        width:100%;
        padding-top: 40px;
        @media (max-width:767px){
            padding-top: 20px;
        }
        @media (min-width:768px) and (max-width:1023px){
            padding-top: 30px;
        }
        @media (min-width:1024px) {
            flex-direction: row;
        }
        &-down{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            @media (max-width:767px){
           
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            height: 100%;
            }
        }
        &-mobile{
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            background: black;
            z-index: 1;
        }
    }
    &-img{
        max-width: 210px;
        @media (max-width:767px){
            margin: 0 0 0 var(--mobile);
            max-width: 170px;
        }
        @media (max-width:350px){
            margin: 0 0 0 var(--iphone);
            max-width: 160px;
        }
        @media (min-width:768px) and (max-width:1023px){
            margin: 0 0 0 var(--planshet);
            max-width: 170px;
        }
        &-mobile{
            margin: 20px var(--mobile);
            max-width: 170px;
            @media (max-width:350px){
                margin: 20px var(--iphone);
                max-width: 160px;
            }
            @media (min-width:768px) and (max-width:1023px){
                margin: 30px var(--planshet);
            }
        }
        &-down{
            max-width: 210px;
            @media (max-width:767px){
                max-width: 200px;
            }
            @media (min-width:768px) and (max-width:1023px){
                margin-left: var(--planshet);
                max-width: 200px;
            }
        }
    }
    &-phones{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        @media (max-width:767px){
            display: none;
        }
        @media (min-width:768px) and (max-width:1023px){
            flex-direction: row;
            margin-left: 180px;
        }
        &-mobile{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            @media (min-width:768px) and (max-width:1023px){
                width: 55%;
                margin: 0 auto;
            }
        }
        &-down{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: auto;
        @media (max-width:767px){
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            }
            @media (min-width:768px) and (max-width:1023px){
                margin-right: var(--planshet);
            }
        }
    }
    &-phone{
        color: var(--white-color);
        font-family: Oswald;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;    
        letter-spacing: 0.04em;
        text-transform: uppercase;
        display: inline-block;
        text-decoration: none;
        @media (min-width:768px) and (max-width:1023px){
            margin-right: 20px;
        }
        &-down{
            color: var(--white-color);
            font-family: Oswald;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;    
            letter-spacing: 0.04em;
            text-transform: uppercase;
            display: inline-block;
            text-decoration: none;
        }
    }
    &-list{
        display: flex;
        margin-left: 50px;
        @media (max-width:1023px){
            display: none;
        }
        &-mobile{
            display: flex;
            flex-direction: column;
             align-items: center;
             margin-top: 40px;
             @media (max-width:350px){
                margin-top: 10px;
             }
             @media (min-width:768px) and (max-width:1023px){
                margin-top: 89px;
             }
        }
        
        &-item{
            //list-style-type: none; 
            margin-right: 20px;
            color: var(--white-color);
            font-family: 'Oswald';
            font-weight: 600;
            line-height: 64px;
            font-size: 17px;
            text-decoration: none;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            &:hover{
                color:var(--red-color);
                text-decoration: underline;
            }
            &-mobile{
                font-family: Oswald;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 58px;
                text-decoration: none;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: var(--white-color);
                            &:hover{
                color:var(--red-color);
                text-decoration: underline;
            }
            @media (max-width:350px){
                line-height: 50px;
            }
            @media (min-width:768px) and (max-width:1023px){
                margin-bottom: 20px;
            }
            }
        }

    }
}
a:active{

        color:var(--red-color);
        text-decoration: underline;

}
//burger`s styles
.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}

.hamburger {
    margin: 0 auto;
    //margin-top: 30px;
    width: 30px;
    height: 21px;
    position: relative;
}

.hamburger .bar {
    padding: 0;
    width: 30px;
    height: 3px;
    background-color: var(--white-color);
    display: block;
    border-radius: 0px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 9px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}

.hamburger4 .bar4 {
    top: 9px;
}

.hamburger4 .bar5 {
    bottom: 0px;
}

.hamburger4 .bar {
    transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
    width: 1px;
    transform: rotate(90deg);
    left: 13.5px;
}

.hamburger4 .bar3 {
    width: 1px;
    left: 13.5px;
    
}

.checkbox4:checked + label > .hamburger4 > .bar1{
    top: 13.5px;
    background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2{
    left: 0px;
    width: 24px;
    transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3{
    left: 0;
    width: 24px;
    transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4{
    background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5{
    bottom: 13.5px;
    background-color: transparent; 
}
.navigation{
    margin: 0 auto;
    width: 30px;
    height: 21px;
    position: absolute;
    top: 35px;
    right: var(--mobile);
    @media (max-width:350px){
        right: var(--iphone);
    }
    @media (min-width:1024px) {
        display: none;           
    }
     @media (min-width:768px) and (max-width:1023px){
        right: var(--planshet);
        top: 44px;
     }
    &-but{
        margin: 43px var(--mobile) 35px;
        @media (max-width:350px){
            margin: 10px var(--iphone) 35px;
        }
        @media (min-width:768px) and (max-width:1023px){
            margin: 127px var(--mobile) 35px;
        }
    }
    &-nonVisible{
        display: none;
    }
    &-mobileButton{
        width: 30px;
        height: 25px;
        display: inline-block;
        margin: 23px 15px 0 0;
        @media (min-width:320px) {
            margin: 14px var(--iphone) 0 0;
        }
        @media (min-width:350px) {
            margin: 14px var(--mobile) 0 0;
        }
        @media (min-width:768px){
            margin: 14px var(--planshet) 0 0;
        }
        @media (min-width:1024px) {
            display: none;           
        }
    }
}
