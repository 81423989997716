.button{
    width: 100%;
    display: inline-block;
    background: var(--red-color);
    height: 60px;
    font-family: 'PT Sans Narrow';
    font-size: 18px;
    line-height: 40px;
    border: none;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--white-color);
    cursor:pointer;
    &-link{
        text-decoration: none;
    vertical-align: middle;
    line-height: 56px;
    }
    &-wrap{
        display: block;
        min-width: 218px;
    }
    &:hover{
        box-shadow: 0 7px 20px #e8363a;
    }
    &-disabled{
        //opacity:0.7;
        box-shadow:none
    }
}