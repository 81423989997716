.first{
    &-position{
        position: relative;
    }
    &-wrap{
        height: 650px;
        background-color: aquamarine;
        background: linear-gradient(90deg, #201D1D 2%, rgba(32, 29, 29, 0) 33%),linear-gradient(180deg, #201D1D 4%, rgba(32, 29, 29, 0) 70%),url('../../img/gears.png');
        background-size: cover;
        @media (max-width:767px){
            background-position: center, center,-618px -16px;
        }
        @media (min-width:768px) and (max-width:1023px){
            height: 650px;
            background: linear-gradient(
                90deg, #201D1D 0.1%, rgba(32, 29, 29, 0) 3%), linear-gradient(
                180deg, #201D1D 4%, rgba(32, 29, 29, 0) 70%), url('../../img/gears.png');
            background-size: cover;
            background-position: center, center, -325px 0px;
        }
    }
    &-headers{
        width:60%;
        margin-top: 90px;
        @media (max-width:767px){
            width: 100%;
            margin-top: 40px;
        }
        @media (min-width:768px) and (max-width:1023px){
            width: 100%;
            margin-top: 50px;
            text-align: center;
        }
    }
    &-header{
        color:var(--white-color);
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 80px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        @media (max-width:767px){
            font-size: 28px;
            line-height: 44px;
            font-weight: bold;
            text-align: center;
            padding: 0 var(--mobile);
        }
        @media (max-width:350px){
            padding: 0 var(--iphone);
        }
         @media (min-width:768px) and (max-width:1023px){
            font-size: 56px;
            line-height: 69px;
         }
    }
    &-span{
        display: inline-block;
        width: 100%;
        font-family: PT Sans Narrow;
        font-style: normal;
        font-weight: normal;
        font-size: 58px;
        line-height: 80px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        margin-bottom: 32px;
        color: var(--grey-color);
        @media (max-width:767px){
            font-size: 38px;
            line-height: 40px;
            margin-bottom: 17px;
            letter-spacing: 0.7px;
            display: inline-block;
            margin-top: 20px;
        }
        &-second{
            margin-top: 32px;
            display: inline-block;
            @media (max-width:767px){
                text-transform: uppercase;
                letter-spacing: 0.04em;
                font-size: 61px;
                margin-top: 32px;
                display: inline-block;
            }
        }
    }
    &-link{
        display: none;
        @media (max-width:767px){
            color: var(--white-color);
            text-decoration: none;
            display: inline-block;
            width: 100%;
        }
    }
    &-city{
        display: none;
        @media (max-width:767px){
            display: inline-block;
        }
    }
  
}
