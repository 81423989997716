  
// .modalW {
//   z-index: 5;
//   background-color: #ffffff;
//   opacity: 1;
//   max-height: 82%;
//   height: 680px;
//   width: 100%;
//   @media (min-width:768px){
//     max-height: 91%;
//     height: 750px;
//     width: 65%;
//     }
//     @media (min-width:1024px){
//       max-height: 91%;
//       width: 670px;
//         }
//         &-second{
//           padding: 20px 20px 0 20px;
//           @media (min-width:1100px){
//             position: absolute;
//             height: 500px;
//             width: 382px;
//             padding: 30px 30px 0 30px;
//             right: 50px;
//             top: 3px;
//             background: var(--white-color);
//               }
//         }
//    &-third{
//           height: 91%;
//           width: 700px;
//           margin: 0 auto;
//           position: relative;
//           top: 50%;
//           transform: translateY(-50%);
//   }
// }
.modalW{
  position: fixed;
    // width: 100vw;
    // height: 100vh;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0px;
    background: rgba(0,0,0,0.8);

  &-second{
    position: fixed;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    background: rgba(0,0,0,0.8);
  }
  &-third{
    position: absolute;
    top: -1%;
    left: 1%;
  }
}