.footer{
    height: 160px;
    background: #111111;
    @media (max-width:767px){
        height: 230px;
    }
    &-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    &-navigation{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    &-nav{
        display: flex;
        flex-direction: row;
        align-self: end;
        list-style: none;
        margin: 0;
        &-item{
            color: whitesmoke;
            
            &:not(:last-child){
                margin-right: 20px;
            }
        }
    }
    &-img{
        max-width: 250px;
    }
    &-tel{
        display: flex;
        flex-direction: column;
        align-self: end;
        &-item{
            color: whitesmoke;
        }
    }


}