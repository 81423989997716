.anim{
  &-preloader{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}
.load {
  max-width: 200px;
  width: 100%;
  height: 50px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}

  @media (max-width: 768px ) {
    .load{width: 100%;} 
  }
  @media (max-width: 480px) {
    .load{width: 100%;} 
  }
  @keyframes rotation {
    100% {
      transform: rotate(360deg); } }


  
.loader-5{
  width : 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: rotation 2s linear infinite;
}
  .loader-5:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    width:   56px;
    height:   56px;
    border-radius: 50%;
    border: 3px solid ;
    border-color: var(--red-color) transparent;
  }