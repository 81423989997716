.success{
    padding: 15px;
    background: white;
    min-height: 455px;
    @media (min-width:768px){
        padding: 40px;
    }
    @media (min-width:1024px){
        padding: 55px 55px 40px;
        max-width: 400px;
    }
    &-title{
        font-size: 50px;
        line-height: 40px;
        text-align: center;
        @media (min-width:768px){
            font-weight: bold;
            font-size: 42px;
            line-height: 45px;
            margin-bottom: 68px;
        }
    }
    &-first{
        padding-top: 25px;
        text-align: center;
    }
    &-second{
        font-style: italic;
        font-weight: 600;
        margin: 0;
        padding-top: 20px;
        &-mess{
            font-style: italic;
            font-size: 25px;
            font-weight: 600;
            padding-bottom: 30px;
            padding-top: 20px;
            text-align: center;
        }
    }
    &-third{
        font-style: italic;
    margin: 0;
    //padding-bottom: 20px;
    }
    &-fourth{
        padding-bottom: 100px;  
        text-align: center;
    }
    // & p:not(:last-child) {
    //     margin-bottom: 30px;
    // }
    &-btn{
        width:100%;
    }
}
.image{

    width:100%;
    height: 40px;
    &-img{
        max-width: 70px;
        @media (min-width:768px){
            max-width: 100%;
            width: 40px;
            height: 40px;
        }
    }
    &-figure{
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
}