.generalModal {  
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__fromModal{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
    &__container{
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-direction: column;
      height: 100%;
    
      .sub-title{
          margin-top: 35px;
      }
      .text{
        text-align: left;
      height: 80%;
      overflow: hidden;
      @media (min-width:768px) and (max-width:1023px){
        width: 500px;
      }
      }
    }
  

  &__close{
    width: 40px;
    height: 40px;
    position: relative;
    border: none;
    cursor: pointer;
    background: transparent;
    top: 10px;
    right: 10px;

    &-wrap{
      width: 450px;
      height: 600px;
      text-align: right;
      background: var(--white-color);
      @media (max-width:374px){
        width: 360px;
      }
      @media (min-width:375px) and (max-width:399px){
        width: 375px;
      }
      @media (min-width:400px) and (max-width:450px){
        width:400px
      }
      @media (min-width:768px) and (max-width:1023px){
        width:600px;
      }
      &-success{
        top: 40px;
        left: 46%;
        position: relative;
        // @media (min-width:768px){
        //   top: 0px;
        //   right: 0px;
        // }
      }
      &-img{
        position: absolute;
        top: 0px;
        left: 320px;
        @media (min-width:768px){
          top: -10px;
          left: 100%;
          }
      }
    }

    &:before,&:after{
      content: '';
      position: absolute;
      width: 65%;
      height: 2px;
      background-color: var(--red-color);
      top: 50%;
      left: 50%
    }
    &:before{
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after{
      transform: translate(-50%, -50%) rotate(-45deg);
    }

  }

  &__br{
    @media screen and (max-width: 340px) {
     display: none;
    }
  }

  &__title{
    font-size: 27px;
    margin: 90px 0px 60px;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  &__content{
    background-color: var(--bg-very-light-blue);
    padding: 20px;
    margin-bottom: 20px;
    letter-spacing: 0.1px;
    font-size: 14px;
    @media screen and (max-width: 500px) {
      overflow: auto;
      max-height: 420px;
    }
    @media screen and (max-width: 380px) {
      max-height: 340px;
    }
    @media screen and (max-width: 350px) {
      padding: 10px;
      margin-bottom: 14px;
      max-height: 290px;
    }
    @media screen and (min-width: 768px) {
      padding: 25px;
    }
  }

  &__btn{
    &-wrap{
      display: flex;
      justify-content: center;
    }

    &:hover {
      background-color: var(--bg-btn-main-hover);
    }

    width: 162px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--bg-btn-main);
    border-radius: 27px;
  }
}