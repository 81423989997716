.carousel{
    padding: 90px 0 150px 0;
    @media (max-width:767px){
        padding: 50px 0 150px 0;
    }
    @media (min-width:768px) and (max-width:1023px){
        padding: 64px 0 150px 0;
    }
    &-photo{
        width:400px;
        height: 400px;
        padding: 0 10px;
        @media (max-width:767px){
            width: 344px;
            height: 344px;
            padding: 0 var(--mobile);
        }
        @media (max-width:350px){
            width: 310px;
            height: 310px;
            padding: 0 var(--iphone);
        }
        @media (min-width:768px) and (max-width:1023px){
            width: 368px;
        } 
        @media (min-width:1024px) and (max-width:1249px){
            width: 490px;
        } 
    }
    &-header{
        margin-bottom: 40px;
    }
}
.slick-prev {
    //position: relative;
    left: 2px;
    height: 80px;
    width: 80px;
    background-color: var(--red-color);
    z-index: 2;
    &::before{
        content:url('../../img/arrowLeft.svg');
        opacity: 1;
        color:  var(--white-color);
    }
}
.slick-next {
    //position: relative;
    right: 0px;
    width: 80px;
    height: 80px;
    background: var(--red-color);
    &::before{
        content: url('../../img/arrowRight.svg');
        opacity: 1;
        color: var(--white-color);
    }
}
.slick-list{
    margin: 0 -8px;
    @media (max-width:1023px){
        margin: 0;
    }
}
.slick-dots li button:before {
    content: "";
}
.slick-dots{
    bottom: -27px;
    position: relative;
    @media (max-width:767px){
        bottom: 0px;
    }
    & li button{
        display: block;
        width: 43px;
        height: 5px;
        padding: 3px;
        margin: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: #DFDFDF;
        @media (max-width:767px){
            display: block;
            width: 15%;
            height: 6px;
            padding: 0px 10px;
        }
    }
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 5px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    @media (max-width:767px){
        width: 4%;
        height: 5px;
        padding: 0;
    }
}
.slick-dots li.slick-active > button{
    background: var(--red-color);
    &:hover{
        box-shadow: 0 7px 20px #e8363a;
    }
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: var(--white-color);
    outline: none;
    background: var(--red-color);
    box-shadow: 0 7px 20px #e8363a;
}