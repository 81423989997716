.contacts{

    background: #F3F3F3;
    &-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 90px 0 90px 0;
        @media (max-width:767px){
            flex-direction: column;
            padding: 0 var(--mobile) 20px;
        }
        @media (max-width:350px){
            flex-direction: column;
            padding: 0 var(--iphone) 20px;
        }
        @media (min-width:768px) and (max-width:1023px){
            flex-direction: column;
            padding: 0px 0 20px 0;
        }
    }
    &-map{
        width: 684px;
        height: 320px;
        border: 1px solid var(--grey-color);
        @media (max-width:767px){
            width: 344px;
        }
        @media (max-width:350px){
            width: 100%;
        }
        @media (min-width:768px) and (max-width:1023px){
            width: 728px;
            margin: 0 var(--planshet);
        }
    }
    &-group{
        @media (min-width:768px) and (max-width:1023px){
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 var(--planshet);
        }
    }
    &-tel{
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.04em;
        color: var(--grey-color);
        display: flex;
        align-items: center;
        margin: 20px 0;
        text-decoration: none;
        @media (max-width:767px){
            font-size: 24px;
        }
        @media (min-width:768px) and (max-width:1023px){
            font-size: 20px;
            margin: 0px 0 34px 0;
        }
    }
    &-adress{
        @media (max-width:767px){
            padding-bottom: 46px;
            padding-top: 40px;
        }
    }
    &-img{
        margin-right: 20px;
    }
    &-header{
        margin-bottom: 40px;
        padding-bottom: 0px;
        @media (max-width:767px){
            margin-left: 0;
        }
        @media (min-width:768px) and (max-width:1023px){
            background: var(--white-color);
            margin-bottom: 30px;
            padding-bottom: 30px;
        }
    }
    &-point{
        font-size: 23px;
    letter-spacing: 0.1px;
    @media (max-width:767px){
        font-size: 19px;
    }
    }
    &-hidden{
        @media (max-width:767px){
            display:none;
        }
    }

}