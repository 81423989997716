.offer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: stretch;
    margin-top: var(--header-margin);
            @media (max-width:767px){
                margin-top: 0;
            }
    &-top{
        @media (max-width:767px){
            padding-top: 30px;
        }
        @media (min-width:768px) and (max-width:1024px){
            padding-top: 80px;
        }
    }
    &-wrap{
        position: relative;
        margin-right: 15px;
        margin-bottom: 15px;
        &:nth-child(2n){
            margin-right: 0;
        }
        @media (max-width:767px){
            margin-bottom: 10px;
            width: 100%;
        }
        &:nth-last-of-type(3) div ul li{
            margin-bottom: 20px;
        }
    }
    &-img{
        max-width: 612px;
        height:100%
    }
    &-header{
        color: var(--white-color);
        position: absolute;
        left: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 34px;
        line-height: 40px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        margin: 40px 0 0 0;
        @media (max-width:767px){
            top: 0;
            left: var(--mobile);
        }
                @media (max-width:350px){
            top: 0;
            left: var(--iphone);
        }

    }
    &-h2{
        font-size: 30px;
        margin: 20px;
    }
    &-button{
        position: absolute;
        left: 40px;
        bottom: 40px;
        display: flex;
        align-items: center;
        @media (max-width:767px){
            bottom: 25px;
            left: var(--mobile);
        }
        @media (max-width:350px){
            bottom: 25px;
            left: var(--iphone);
        }
        &-span{
            color: var(--white-color);
            margin-left: 30px;
            font-size: 20px;
            line-height: 40px;
            text-transform: uppercase;
        }
    }
    &-list{
        position: absolute;
        left: 20px;
        top: 102px;
        list-style-type: square;
        width: 75%;
        margin: 0;
        @media (max-width:767px){
            top:98px;
            padding-left: calc((100vw - 354px)/2);  
            margin: 0;
        }
        &-item{
            color: var(--white-color);
            font-size: 16px;
            line-height: 26px;
            font-weight: bold;
            @media (max-width:767px){
                line-height: 25px;
            }
        }
        &-li{
            color: var(--red-color);
            margin-bottom: 5px;
        }

    }
    &-visiblity{
        width:612px;
        height: 366px;
        background-color: var(--grey-color);
        @media (max-width:1023px){
            width:100vw;
            height: 343px;
        }
    }
}