@import './fonts.scss';
@import './variables.scss';

body{
  font-family: 'PT Sans Narrow','Oswald';
  padding:0px;
  margin: 0px;
  display: block;
}

h2{
  text-align: left;
  font-family: PT Sans Narrow;
font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 64px;
letter-spacing: 0.04em;
text-transform: uppercase;
margin: 0px;
@media (max-width:767px){
  margin: 0 0 0 var(--mobile);
  font-size: 34px;
}
@media (max-width:350px){
  margin: 0 0 0 var(--iphone);
  font-size: 34px;
}
@media (min-width:768px) and (max-width:1023px){
  //margin: 0 0 0 var(--mobile);
  text-align: center;
  //font-size: 34px;
}
}

.container{
  max-width: 1240px;
  margin: 0 auto;
  @media (max-width:1023px) {
    max-width: 100%;
    margin: 0;
  }
  @media (min-width:1024px) and (max-width:1250px){
    max-width: 1000px;
    margin: 0 auto;
  }
}

// .container.first-position{
//   @media (max-width:767px){
//     margin:0;
//     padding-left: calc((100% - 344px)/2);
//   }
// }
